import React, { Component } from "react"
import Layout from "../components/layout"
import FaqContentThree from "../components/Faq/FaqContentThree"
import FreeTrialArea from "../components/Home/FreeTrialArea"

class FAQ extends Component {
  render() {
    return (
      <React.Fragment>
        <Layout>
          <FaqContentThree />
          <FreeTrialArea />
        </Layout>
      </React.Fragment>
    )
  }
}

export default FAQ
