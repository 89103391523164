import React, { Component } from "react"
import { Link } from "gatsby"

class FreeTrialArea extends Component {
  render() {
    return (
      <section className="free-trial-area pb-100">
        <div className="container">
          <div className="free-trial-content">
            <h2>We pride ourselves on our customer support</h2>
            <p>
              Please reach out to us for any questions regarding our software,
              sales, or partnerships.
            </p>

            <Link to="/contact" className="default-btn mr-3">
              <i className="bx bxs-envelope"></i>
              Contact Us
              <span></span>
            </Link>
          </div>
        </div>
      </section>
    )
  }
}

export default FreeTrialArea
