import React, { Component } from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"

class FaqContentThree extends Component {
  render() {
    return (
      <section className="faq-area ptb-100">
        <div className="container">
          <div className="contact-inner">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-12 col-md-12">
                  <div className="faq-accordion">
                    <h2>Topics for frequently asked questions:</h2>

                    <Accordion allowZeroExpanded={true}>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            The F3 key is not working in HASS2020
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            In Windows 10, the F3 key is normally Find|Next. If
                            you wish for the F3 key to delete lines, go to
                            Options|Edit Options|Operations Options and turn on
                            F3 Deletes instead of Find|Next (next to the red
                            star).
                          </p>
                          <img
                            src={require("../../images/faq_f3.png")}
                            alt="image"
                          />
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            PRV Fitting Loss
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            The PRV calculations use the diameter of the
                            downstream pipe to calculate the size of the angle
                            or gate valve that is the friction loss of the PRV
                            when the pressure in the pipe is already below the
                            setting. The angle and gate valve equivalent fitting
                            length is kept in the Options|Edit PRV Fitting Loss
                            menu option. Sometimes the values in this table
                            don’t get loaded correctly. To fix this, open the
                            dialog using the menu option and push the reset
                            button and click OK. This will load values into the
                            table.
                          </p>
                          <p>The error message for this is:</p>
                          <p>
                            Unable to find the downstream pipe. The downstream
                            pipe size at the PRV must be described in the data
                            entry with a nominal diameter number.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>Licensing</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            HRS Systems is now using a licensing method that
                            validates the license on each computer. This is
                            checked during startup and at other times during
                            program execution. To do this, the software needs to
                            check the software and computer information against
                            what is stored on the server. Your computer
                            information is not directly stored on the internet.
                            It is encoded so that your computer can be verified,
                            but your computer information cannot be retrieved.
                            This check must occur at least every two weeks. The
                            software checks this every time you startup so that
                            you have a full two weeks between connections.
                          </p>
                          <p>
                            Since each computer is licensed individually when
                            you no longer need HASS on a specific computer, it
                            should be deactivated and removed. To Deactivate a
                            license from a computer, go to Help|Deactivate
                            License.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Install issues
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            When installing HASS, especially HASS2020, it is
                            best to let HASS do all the work and change the
                            values later unless you know exactly where all of
                            your directories should go and have them set up.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Program Directory
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            This is the location of the HASS system files. At
                            times HASS needs to access these files and needs to
                            know where the latest ones are kept. The default
                            location is <p>c:\program files (x86)\hrs
                            systems\hass2020</p> <p>or</p> <p>c:\program files\hrs
                            systems\hass88</p> Unless you directed the setup
                            program to install HASS in a different directory,
                            the system directories should be as previously
                            mentioned.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Data Directory
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            The data directory stores files that need to be
                            altered during program execution. Over the years,
                            Windows has changed the security protocols so that
                            most programs cannot modify files in the program
                            directory without increased permissions. So, HASS
                            stores the files in the data directory. It works
                            best to place the data directory under the Documents
                            directory. The data directories have three types –
                            Data Directory, Pipe Table Directory, and Lock Box
                            Directory.
                          </p>
                          <p>
                            The Data Directory holds most of the support files
                            and is the first place to store the system
                            definition files and others.
                          </p>
                          <p>
                            The Pipe Table Directory holds the pipe table files.
                            This is separate so that a company can store their
                            pipe table files on a server, and everyone can
                            access them.
                          </p>
                          <p>
                            The Lock Box Directory contains backups of the
                            system data files.
                          </p>
                          <p>
                            If you don’t have specific targets for these
                            directories, let HASS suggest them.
                          </p>
                          <p>
                            In HASS2020, there are both English and Metric sets
                            of data directories. These should be different
                            directories. The most direct values are hassdata and
                            hassdatm for these directories under the documents
                            directory.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Find my Files
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            When a new version of HASS is installed, it tries to
                            use the previous data directory. Sometimes this is
                            not possible, or it is in a place that HASS can’t
                            write to because of security protocols. To find your
                            files go to File Explorer, which is the icon that
                            looks like a manila folder in a metal stand at the
                            bottom of the windows screen. Go to the c: drive and
                            then search for the files that your software uses.
                            For HASS, this is .sdf, and for HASSHouse, this is
                            .rdf. For .sdf files put in *.sdf.
                          </p>
                          <p>
                            Sometimes the security software on a computer will
                            quarantine HASS and put everything in a location
                            under the AppData directory. The full path is
                            usually c:\Users\(User Name)\AppData\Local\etc.
                            Sometimes it is under AppData\Roaming, and even
                            other times, it is under a temp directory. The
                            search process will show the directory that has the
                            files. The files can then be copied to the desired
                            directory.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Modify Pipe Tables
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            Pipe Tables are made to be modified. To avoid
                            problems moving files from computer to computer, a
                            standard pipe table is included when HASS is
                            shipped, and it can’t be modified. But it is easy to
                            make a copy and edit that. The steps are as follows:
                          </p>
                          <ol type="1">
                            <li>
                              Make sure that the pipe table directory is set to
                              a directory that HASS can edit.
                            </li>
                            <li>Go to Utilities|View Standard.pip</li>
                            <li>
                              Go to File|Save as and save the pipe table with a
                              new name. Be sure to keep the .pip extension. It
                              is also best to keep the name relatively short – 8
                              or fewer characters is best, but it can be longer.
                            </li>
                            <li>
                              Make any changes you want to the pipe table.{" "}
                            </li>
                            <li>Save your changes.</li>
                            <li>
                              Go to File|Close to stop editing the pipe table.
                            </li>
                            <li>
                              When you have an open sdf use the Calc|Calc
                              Information menu item to select your new pipe
                              table and set the pipe table page.
                            </li>
                          </ol>
                          <p>
                            You can set the initial pipe table and pipe table
                            page when a new sdf is created. For HASS2020 this is
                            set in Options|Edit Options|Initial Values Options.
                            For HASS 8.8, this is set in Options|Edit System
                            Parameters.
                          </p>
                          <p>
                            If the desired pipe table is not present, check your
                            pipe table directory. Sometimes it helps to exit
                            HASS and start it up again.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            The Cover Sheet doesn’t display automatically when I
                            create a new file.
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            HASS 2020 gives the user some extra flexibility. If
                            it doesn’t come up automatically, go to the
                            Options|Edit Options|Display Options menu item and
                            check “Display the Cover Sheet Dialog when opening
                            an sdf”
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            When I try to create a cover sheet, I get a dialog
                            with no items in it.
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            This means that the master cover sheets are not in
                            the data directories. In HASS2020, first, try
                            Help|Extract Support Files. This will copy them if
                            all is well. If that doesn’t work, copy the .mcs
                            files from the HASS program directory to the HASS
                            data directory, and that should resolve the problem.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>

                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Extra pages or not enough pages
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <p>
                            HASS calculates the number of pages required to
                            print your document and suggests all those pages.
                            Sometimes the count is incorrect because the printer
                            is changed after the calculation is made or there is
                            a quirk from the font or paper size. Improving this
                            is on our “To Do” list. Until it is fixed, there is
                            an option to increase or decrease the number of
                            pages required. In HASS2020, this in the
                            Options|Edit Options|Print Options menu item. In
                            HASS 8.8, this is the Options|System Parameters menu
                            item. HASS will add the number of extra pages to the
                            output that are entered.
                          </p>
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default FaqContentThree
